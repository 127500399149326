import React, { useEffect, SyntheticEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { MixpanelHelpers, MIXPANEL_ACTION_HELP } from '../../utils/mixpanel';
import { updateUIPairs } from '../../redux/modules/ui';

type Props = {
  helpType: ?string,
  footerText?: string,
  footerHref?: string,
  footerOnClick?: () => void,
  onHelpClick?: ?() => void,
};

const FrameFooter = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (props.helpType) {
      dispatch(updateUIPairs({ helpType: props.helpType }));
    }
  }, []);

  const onClick = (event: SyntheticEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_HELP,
    });

    if (pathname === '/help') {
      history.goBack();
    } else {
      typeof props.onHelpClick === 'function' ? props.onHelpClick(event) : history.push('/help');
    }
  };

  if (props.footerText && props.footerOnClick) {
    let buttonConfig = {};

    if (props.footerOnClick && !props.footerHref) {
      buttonConfig = {
        id: 'link-help',
        onClick: props.footerOnClick,
        href: '#',
      };
    } else {
      buttonConfig = {
        href: props.footerHref,
        id: 'link-help',
        target: '_blank',
        rel: 'noreferrer',
        onClick: props.footerOnClick,
      };
    }

    return (
      <footer>
        <a data-testid="footer-btn" {...buttonConfig}>
          {props.footerText}
        </a>
      </footer>
    );
  }

  return (
    <footer>
      <a href="#" id="link-help" onClick={onClick} data-testid="footer-btn">
        {pathname === '/help' ? '戻る' : props.footerText || 'ログインやご利用方法でお困りですか？'}
      </a>
    </footer>
  );
};

export default FrameFooter;
