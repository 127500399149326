import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Frame from '../Frame/FrameContainer';
import libphonenumber from 'google-libphonenumber';

import { useDispatch, useSelector } from 'react-redux';
import { updateEmailUpdatePairs } from '../../redux/modules/email-update';
import useRecaptcha from '../../hooks/use-recaptcha';
import { authTypeMap } from '../../constants';

import styles from './EmailUpdate.scss';
import { requestOtpCode } from './helpers';
import { emailReminderCodes } from '../../constants/error-codes';
import {
  MIXPANEL_ACTION_BUTTON_CLICK,
  MIXPANEL_ACTION_FIELD_EDIT,
  MixpanelHelpers,
} from '../../utils/mixpanel';

const EmailUpdate = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const phone = useSelector(state => state.emailUpdate.phoneForEmailUpdate);

  const [reCapatchaToken, setReCapatchaToken] = useState('');
  const [phoneInputData, setPhoneInputData] = useState({
    value: phone || '',
    error: null,
    isFocused: false,
    isTouched: false,
  });

  const { getToken, isReady: isCaptchaReady } = useRecaptcha(
    '6LfAStYpAAAAAK4IruDhBd6xrZeVgVTtKX3lwEXV'
  );

  const isValidPhoneNumber = (value) => {
    if (!value) {
      return '必須項目です。';
    }
    
    let error = null;
    const digitsOnly = value.replace(/\s/g, '');
    const length = digitsOnly.length;
  
    if (value.trim() === '') {
      error = '必須項目です。';
    } else if (!/^[\d\s]*$/.test(value)) {
      error = '数字で入力してください';
    } else if (length < 11 || length > 12) {
      error = '携帯電話番号を11桁で入力してください';
    }
    return error;
  };
  

  const onFocus = event => {
    event.preventDefault();
    event.currentTarget.scrollIntoView(true);
    setPhoneInputData(prev => ({ ...prev, isFocused: true, isTouched: true }));
  };

  const onBlur = () => {
    setPhoneInputData(prev => {
      const error = isValidPhoneNumber(prev.value);
      if (!error && prev.value.trim() !== '') {
        return { ...prev, error: null, isFocused: false, isTouched: true };
      } else {
        return { ...prev, error, isFocused: false, isTouched: prev.value.trim() !== '' };
      }
    });
    MixpanelHelpers.trackAction({
      pathname: location.pathname,
      actionName: MIXPANEL_ACTION_FIELD_EDIT,
    });
  };

  const onChange = ({ currentTarget: { value } }) => {
    setPhoneInputData(prev => {
      const error = isValidPhoneNumber(value);
      return { ...prev, value, error, isTouched: true };
    });
  };

  const initiateEmailReminder = () => {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const number = phoneUtil.parseAndKeepRawInput(phoneInputData.value, 'JP');
    const phoneNumber = phoneUtil.format(number, libphonenumber.PhoneNumberFormat.E164);
    requestOtpCode({
      phoneNumber,
      pinCodeDispatchMethod: authTypeMap.SMS.toUpperCase(),
      reCapatchaToken,
    }).then(({ data: { data, errors } }) => {
      if (!errors && data.initiateMyEmailReminder) {
        dispatch(
          updateEmailUpdatePairs({
            phoneForEmailUpdate: phoneInputData.value,
            reCapatchaToken,
            session: data.initiateMyEmailReminder,
          })
        );
        history.push('/email-update-otp');
      }
      if (errors.some(({ message }) => message === 'wrong phone_number')) {
        setPhoneInputData(prev => ({
          ...prev,
          error: '電話番号を正しく入力してください',
        }));
      } else if (errors.some(({ extensions }) => extensions.code === 403)) {
        history.push(`/error/${emailReminderCodes.PHONE_24H_ATTEMP}`);
      } else if (errors.some(({ extensions }) => extensions.code === 401)) {
        // unauthorized error here
      }
    });
    MixpanelHelpers.trackAction({
      pathname: location.pathname,
      actionName: MIXPANEL_ACTION_BUTTON_CLICK,
    });
  };

  useEffect(() => {
    const fetchRecaptchaToken = async () => {
      if (isCaptchaReady) {
        const token = await getToken('WEB/REMIND_EMAIL');
        setReCapatchaToken(token);
      }
    };
    fetchRecaptchaToken();
  }, [isCaptchaReady]);

  return (
    <Frame hasHeader={true} hasFooter={false} showInstallments={false}>
      <div className={styles.content}>
        <p>
          携帯電話番号を入力して、認証コードを受け取ってください。認証コード入力後、ご登録のメールアドレスがSMSに送信されます。
        </p>
        <p>※セキュリティ上の理由により、メールアドレスは一部を隠した状態でお送りします。</p>
        <fieldset>
          <label htmlFor="phone">携帯電話番号</label>
          <input
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            id="phone"
            type="tel"
            name="phone"
            pattern="\d*-*\d*-*\d*"
            placeholder="000 0000 0000"
            value={phoneInputData.value}
          />
          {phoneInputData.error && <span className="error">{phoneInputData.error}</span>}
        </fieldset>
      </div>
      <div className={styles.controls}>
        <button
          id="btn-next"
          className={'btn'}
          disabled={
            phoneInputData.error !== null ||
            phoneInputData.value.trim() === '' ||
            !phoneInputData.isTouched
          }
          onClick={initiateEmailReminder}
        >
          認証コードを受け取る
        </button>
      </div>
    </Frame>
  );
};

export default EmailUpdate;