import axios from 'axios';
import { authTypeMap } from '../../constants';

export const requestOtpCode = ({
  phoneNumber,
  pinCodeDispatchMethod = authTypeMap.SMS,
  reCapatchaToken,
}) => {
  return axios.post('https://apis.paidy-staging.com/paidy/consumers/graphql/public', {
    operationName: 'InitiateMyEmailReminder',
    query:
      'mutation InitiateMyEmailReminder($phoneNumber: String!, $pinCodeDispatchMethod: PinCodeDispatchMethod!, $recaptchaPlatform: Platform!, $recaptchaToken: String!) {\n  initiateMyEmailReminder(\n    phoneNumber: $phoneNumber\n    pinCodeDispatchMethod: $pinCodeDispatchMethod\n    recaptchaPlatform: $recaptchaPlatform\n    recaptchaToken: $recaptchaToken\n  )\n}',
    variables: {
      phoneNumber,
      pinCodeDispatchMethod,
      recaptchaPlatform: 'WEB',
      recaptchaToken: reCapatchaToken,
    },
  });
};

export const submitOtpCode = ({ answer, reCapatchaToken, session }) => {
  return axios.post('https://apis.paidy-staging.com/paidy/consumers/graphql/public', {
    operationName: 'RespondToMyEmailReminderChallenge',
    query:
      'mutation RespondToMyEmailReminderChallenge($session: String!, $answer: String!, $recaptchaPlatform: Platform!, $recaptchaToken: String!) {\n  respondToMyEmailReminderChallenge(\n    session: $session\n    answer: $answer\n    recaptchaPlatform: $recaptchaPlatform\n    recaptchaToken: $recaptchaToken\n  )\n}',
    variables: {
      answer,
      recaptchaPlatform: 'WEB',
      recaptchaToken: reCapatchaToken,
      session,
    },
  });
};