// @flow
/* eslint-disable jsx-a11y/no-autofocus */
import React, { Component, createRef } from 'react';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import { withRouter, Link } from 'react-router-dom';
// components
import Checkbox from '../CheckBox';
import Frame from '../Frame/FrameContainer';
import withValidation from '../Form/withValidation';
import {
  getParsedPhoneNumber,
  errorHandler,
  isLocalStorageSupported,
  scrollToFirstError,
  parsePhoneNumber,
} from '../../utils';
import robustStorage from '../../utils/robust-local-storage';
import validators from '../Form/validators';
import { tierMap } from '../../constants';
import { placeholders } from '../../constants/form';
import styles from './LoginForm.scss';
import {
  MixpanelHelpers,
  LOGIN_DISPLAY_NAMES,
  MIXPANEL_ACTION_FIELD_EDIT,
  MIXPANEL_ACTION_FORM_ERROR,
  MIXPANEL_ACTION_LINK_CLICK,
  MIXPANEL_ACTION_SUBMIT,
  MIXPANEL_ACTION_PAGE_VIEW,
  MIXPANEL_ACTION_REMEMBER_ME,
} from '../../utils/mixpanel';
import { deleteLocalAuth, saveLocalAuth } from '../../redux/modules/auth';
import { CheckedInCircleIcon, EmailIcon, PhoneIcon, QuestionIcon } from './icons';

type Props = {
  tier: string,
  email: string,
  phone: string,
  onSubmit: Function,
  history: any,
  location: Object,
  // rememberMe: ?string,
  // onRememberMeChange: Function,
  updateAuthPairs: Function,
  updateEmailUpdatePairs: Function,
  tocSingleVersion: string,
  tocPlusVersion: string,
  // form validation
  errors: Object,
  setError: Function,
  onValidate: Function,
  onValidateAll: Function,
  successfullyFinished: Boolean,
};

const formValidators = {
  email: validators.email,
  phone: validators.phone,
};

const trackLinkClickToc = () =>
  MixpanelHelpers.trackAction({
    customPath: 'Login',
    actionName: MIXPANEL_ACTION_LINK_CLICK,
    actionItem: 'T&C',
  });

let hasEmailFieldBeenEdited = false;
let hasPhoneFieldBeenEdited = false;

type State = {
  email: string,
  phone: string,
};

class LoginForm extends Component<Props, State> {
  static onFocus = (event: SyntheticEvent<HTMLInputElement>): void => {
    event.preventDefault();

    event.currentTarget.scrollIntoView(true);
  };

  constructor(props) {
    super(props);
    const { email, phone } = this.props;
    const rememberMe = robustStorage.getItem('paidy_remember_me') === 'true' && email && phone;

    this.state = {
      email: email || '',
      phone: phone || '',
      isAuthFailed: false,
      isFocusedEmailField: false,
      isFocusedPhoneField: false,
      rememberMe,
      shouldSkipLoginPage: rememberMe,
      showHelpModal: false,
    };

    this.helpModalRef = createRef();
  }

  UNSAFE_componentWillMount() {
    // This snippet is the core of the Remember Me function.
    // On the next time Checkout is opened, it automatically logs in the consumer.
    if (this.state.rememberMe) {
      this.onSubmit();
      return;
    }

    // If the email and phone are saved in localstorage,
    // Enable Remember Me as well.
    if (robustStorage.getItem('paidy_email') && robustStorage.getItem('paidy_phone')) {
      robustStorage.setItem('paidy_remember_me', 'true');
      this.setState({
        rememberMe: true,
      });
    }

    document.removeEventListener('click', this.handleOutsideClick);
  }

  componentDidMount() {
    const { pathname } = this.props.location;
    if (this.state.shouldSkipLoginPage) {
      return;
    }

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      extraData: {
        'Checkout auth error': 'False',
      },
    });
    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.successfullyFinished && !prevProps.successfullyFinished) {
      setTimeout(() => {
        this.props.updateEmailUpdatePairs({ successfullyFinished: false });
      }, 2000);
    }
  }

  componentWillUnmount() {
    const { pathname } = this.props.location;
    if (this.state.shouldSkipLoginPage) {
      return;
    }
    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      shouldEndTracker: true,
    });
  }

  @autobind
  trackFaqClick() {
    const { pathname } = this.props.location;

    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_LINK_CLICK,
    });
  }

  @autobind
  onToggleHelpModal(opened) {
    this.setState(
      {
        showHelpModal: opened,
      },
      () => {
        if (opened) {
          document.addEventListener('click', this.handleOutsideClick);
        } else {
          document.removeEventListener('click', this.handleOutsideClick);
        }
      }
    );
  }

  @autobind
  handleOutsideClick(event) {
    if (
      this.state.showHelpModal &&
      this.helpModalRef.current &&
      !this.helpModalRef.current.contains(event.target)
    ) {
      this.onToggleHelpModal(false);
    }
  }

  @autobind
  onHelpClick(event: SyntheticEvent<HTMLAnchorElement>) {
    event.preventDefault();
    event.stopPropagation();
    this.onToggleHelpModal(true);
  }

  @autobind
  onChange(event: SyntheticInputEvent<HTMLInputElement>) {
    const {
      updateAuthPairs,
      location: { pathname },
    } = this.props;
    const { name, value } = event.currentTarget;
    const isFieldEmail = name === 'email';

    this.setState({
      [name]: value,
    });

    let finalValue = value;

    if (name === 'phone') {
      finalValue = value.replace(/ /g, '');
    }

    updateAuthPairs({
      [name]: finalValue,
    });

    if (
      (isFieldEmail && !hasEmailFieldBeenEdited) ||
      (name === 'phone' && !hasPhoneFieldBeenEdited)
    ) {
      if (isFieldEmail) {
        hasEmailFieldBeenEdited = true;
      } else {
        hasPhoneFieldBeenEdited = true;
      }

      MixpanelHelpers.trackAction({
        pathname,
        actionName: MIXPANEL_ACTION_FIELD_EDIT,
        actionItem: LOGIN_DISPLAY_NAMES[name],
      });
    }
  }

  @autobind
  onSubmit(): boolean | Promise<any> {
    const {
      email,
      phone,
      history,
      location: { pathname },
    } = this.props;
    const errors = this.props.onValidateAll({ checkTouch: false });
    this.setState({ isAuthFailed: false });

    if (!Object.keys(errors).find(key => !!errors[key])) {
      const parsedPhoneNumber = getParsedPhoneNumber(phone);

      this.props
        .onSubmit(email, parsedPhoneNumber)
        .then(() => {
          !this.state.shouldSkipLoginPage &&
            MixpanelHelpers.trackAction({
              pathname,
              actionName: MIXPANEL_ACTION_SUBMIT,
            });

          if (this.props.tier === tierMap.PLUS) {
            robustStorage.setItem('paidy_last_agreed_toc_plus_version', this.props.tocPlusVersion);
          } else {
            robustStorage.setItem(
              'paidy_last_agreed_toc_single_version',
              this.props.tocSingleVersion
            );
          }

          // If remember me is enabled, save credentials to localstorage
          if (this.state.rememberMe) {
            saveLocalAuth({ email, phone });
          }

          this.props.history.push('/otp');
        })
        .catch(err => {
          // If an invalid number somehow got through the frontend validation
          // and backend returns an error show error message instead of just showing "現在こちらのショップでPaidyが利用できません"
          if (
            err &&
            err.data.details &&
            err.data.details.length &&
            err.data.details[0].message === 'string.japanese.mobile.phone.valid'
          ) {
            this.props.setError({ phone: '携帯電話番号に誤りがあります' });

            return;
          }
          if (
            err &&
            err.data &&
            (err.data.code === 'service.error' ||
              err.data.code === 'consumer.authentication.failed' ||
              err.data.code === 'consumer.phone.mismatch' ||
              err.data.code === 'consumer.email.mismatch')
          ) {
            this.setState({ isAuthFailed: true });
            // Restart page tracking with extra data
            MixpanelHelpers.trackDuration({
              pathname,
              actionName: MIXPANEL_ACTION_PAGE_VIEW,
              shouldEndTracker: true,
            });
            MixpanelHelpers.trackAction({
              pathname,
              actionName: MIXPANEL_ACTION_PAGE_VIEW,
              extraData: {
                'Checkout auth error': 'True',
              },
            });
            MixpanelHelpers.trackDuration({
              pathname,
              actionName: MIXPANEL_ACTION_PAGE_VIEW,
            });
            return;
          }

          errorHandler({ err, history, isRedirectNeeded: false });
          this.props.setError({ emailOrPhone: 'メールアドレスまたは携帯電話番号が異なります' });
        });
    } else {
      scrollToFirstError();

      const erroredFields = Object.keys(errors).filter(key => !!errors[key]);

      MixpanelHelpers.trackAction({
        pathname,
        actionName: MIXPANEL_ACTION_FORM_ERROR,
        extraData: {
          'Errored Fields': erroredFields,
        },
      });
    }

    return false;
  }

  @autobind
  onBlur(event) {
    const { name } = event.currentTarget;

    this.props.onValidate({ name });

    if (name === 'phone') {
      this.setState({ isFocusedPhoneField: false });
    } else if (name === 'email') {
      this.setState({ isFocusedEmailField: false });
    }
  }

  @autobind
  onFocus(event) {
    const { name } = event.currentTarget;

    if (name === 'phone') {
      this.setState({ isFocusedPhoneField: true });
    } else if (name === 'email') {
      this.setState({ isFocusedEmailField: true });
    }
  }

  @autobind
  onRememberMeToggle(rememberMe: boolean) {
    this.setState({
      rememberMe,
      shouldSkipLoginPage: false,
    });

    MixpanelHelpers.trackAction({
      pathname: this.props.location.pathname,
      actionName: MIXPANEL_ACTION_REMEMBER_ME,
      extraData: {
        'Remember Me': rememberMe,
      },
    });

    if (rememberMe) {
      robustStorage.setItem('paidy_remember_me', 'true');
      MixpanelHelpers.registerRememberMeStatus(true);
    } else {
      robustStorage.setItem('paidy_remember_me', 'false');
      deleteLocalAuth();

      MixpanelHelpers.registerRememberMeStatus(false);
    }
  }

  render() {
    const { rememberMe, onRememberMeChange, errors, email, phone } = this.props;

    return (
      <Frame helpType="login" onHelpClick={this.onHelpClick}>
        <div className={styles['scrollable-content']}>
          {this.state.isAuthFailed && (
            <fieldset>
              <span className={`error ${styles['generic-error']}`}>
                メールアドレスまたは携帯電話番号が異なります
              </span>
            </fieldset>
          )}
          <fieldset>
            {!this.state.isFocusedEmailField && <label htmlFor="email" />}
            <span className={styles.label}>メールアドレス</span>
            <input
              onChange={this.onChange}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              id="email"
              type="email"
              name="email"
              placeholder={placeholders.EMAIL}
              value={this.state.email}
              autoFocus
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </fieldset>
          <fieldset>
            {!this.state.isFocusedPhoneField && <label htmlFor="phone" />}
            <span className={styles.label}>携帯電話番号</span>
            <input
              onChange={this.onChange}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              id="phone"
              type="tel"
              name="phone"
              pattern="\d*-*\d*-*\d*"
              placeholder={placeholders.PHONE}
              value={
                this.state.isFocusedPhoneField
                  ? this.state.phone
                  : parsePhoneNumber(this.state.phone)
              }
            />
            {errors.phone && <span className="error">{errors.phone}</span>}
          </fieldset>
          {isLocalStorageSupported() && (
            <fieldset>
              {/*<Checkbox*/}
              {/*  id="remember"*/}
              {/*  name="remember"*/}
              {/*  checked={rememberMe}*/}
              {/*  onChange={onRememberMeChange}*/}
              {/*  title="次回から入力を省略"*/}
              {/*/>*/}
              <Checkbox
                id="remember"
                name="remember"
                checked={this.state.rememberMe}
                onChange={event => {
                  this.onRememberMeToggle(event.target.checked);
                }}
                title="次回から入力を省略"
              />
            </fieldset>
          )}
        </div>
        <div className={styles.controls}>
          <span className={styles.toc}>
            <Link to="/toc" onClick={trackLinkClickToc} id="link-see-toc">
              <span>利用規約・個人情報取扱条項</span>
            </Link>
            <span>に同意して</span>
          </span>
          <button
            id="btn-login"
            className={cx('btn', styles.button)}
            onClick={(event: SyntheticEvent<HTMLButtonElement>) => {
              event.preventDefault();

              this.onSubmit();
            }}
            disabled={!email || !phone}
          >
            次へ
          </button>
        </div>

        {this.props.successfullyFinished && (
          <div
            className={styles['success-email-update-modal-wrapper']}
            onClick={() => this.props.updateEmailUpdatePairs({ successfullyFinished: false })}
          >
            <div className={styles['success-email-update-modal']}>
              <CheckedInCircleIcon />
              <div>
                <span>ご登録のメールアドレスを</span>
                <span>SMSに送信しました。</span>
              </div>
            </div>
          </div>
        )}
        <div className={styles['help-modal-wrapper']} data-testid="help-modal-wrapper">
          <div
            className={cx(styles['help-modal-overlay'], {
              [styles.opened]: this.state.showHelpModal,
            })}
          ></div>
          <div
            className={cx(styles['help-modal'], {
              [styles.opened]: this.state.showHelpModal,
            })}
            ref={this.helpModalRef}
          >
            <div className={styles['help-action']} data-testid="help-action">
              <Link to="/email-update">
                <EmailIcon />
                <span>メールアドレスを忘れた</span>
              </Link>
            </div>
            <div className={styles['help-action']} data-testid="help-action">
              <Link to={{ pathname: 'https://my.paidy.com' }} target="_blank">
                <PhoneIcon />
                <span>携帯電話番号を変更する</span>
              </Link>
            </div>
            <div className={styles['help-action']} data-testid="help-action">
              <Link to="/help" onClick={this.trackFaqClick}>
                <QuestionIcon />
                <span>よくあるご質問を見る</span>
              </Link>
            </div>
          </div>
        </div>
      </Frame>
    );
  }
}

export default withRouter(withValidation(formValidators)(LoginForm));
